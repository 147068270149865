
import { Component, Vue, Watch } from 'vue-property-decorator'
import AsyncComputed from 'vue-async-computed-decorator'
import { Debounce } from 'vue-debounce-decorator'
import {ClientResource, PropertyResource} from '@/resources'
import {isEqual} from 'lodash-es'

@Component
export default class ClientSearchField extends Vue {
  selection: any = null
  search : string = ""
  clientResource = new ClientResource()
  toggleHelper : boolean = false

  get clientUtil() : any {
    return ClientResource
  }

  get propertyUtil() : any {
    return PropertyResource
  }

  mounted() {
    this.$nextTick(()=>{
      this.$root.$on('focusClientSearch', () => {
        window.scrollTo(0, 0);
        //@ts-ignore
        this.$refs.clientSearch.focus()
        setTimeout(() => {
          this.toggleHelper = true
        }, 250);
        setTimeout(() => {
          this.toggleHelper = false
        }, 3000);
      });
    })
  }

  @AsyncComputed({shouldUpdate() {return false}})
  async clients () {
    if (!this.search) {
      return []
    }
    return (await this.clientResource.searchByNameOrAddress).getAssociation({nameOrAddress: this.search, projection: "clientNamesOnlyInlineProperties"})
  }

  getPropertyAddressFromClient(client : any) {
    // TODO assumes one prop
    return this.propertyUtil.getPropertyAddress(client.data().properties[0])
  }

  @Watch('selection')
  onSelectionChanged(newVal : any) {
    const newId = newVal ? newVal.data().id : undefined
    if (newId && newId != this.$route.params.cid) {
      this.$router.push({ name: 'client', params: {cid:newId}})
      this.selection = null
    }
  }

  @Watch('search')
  @Debounce(300)
  onSearchChanged(newVal : any, oldVal : any) {
    if (newVal && !isEqual(newVal, oldVal)) {
      this.$asyncComputed.clients.update()
    }
    else {
      this.search = ""
      this.selection = null
    }
  }
    
}
